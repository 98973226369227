import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import React from 'react'
import { LogoGridFragment } from '../../types/graphql'
import { LogoGrid } from '../LogoGrid'

type Props = {
  data: LogoGridFragment
}

export function LogoGridBlock({ data }: Props): React.ReactElement | null {
  const { heading, logoList } = data

  if (!heading || !logoList || !logoList.length) {
    return null
  }

  return (
    <LogoGrid
      heading={heading}
      logoList={logoList.map(logo => {
        if (!logo || !logo.format) {
          return null
        }

        return logo.format === 'svg' ? (
          <img
            src={logo.url!}
            alt=""
            style={{ width: 128, height: 64, objectFit: 'contain' }}
          />
        ) : (
          <Img fixed={logo.fixed as FixedObject} alt="" />
        )
      })}
    />
  )
}

export const fragment = graphql`
  fragment LogoGridFragment on DatoCmsLogoGrid {
    heading
    logoList {
      format
      url
      fixed(
        width: 128
        height: 64
        imgixParams: { bg: "0FFF", fit: "fillmax" }
      ) {
        ...DatoFixed
      }
    }
  }
`
