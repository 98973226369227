import { css } from '@emotion/core'
import { Link } from 'gatsby'
import React, { HTMLAttributes, ReactElement, ReactNode } from 'react'
import { cx } from '../utils/helpers'
import { WidthWrapper } from './WidthWrapper'

type Props = HTMLAttributes<HTMLDivElement> & {
  text: ReactNode
  ctaText: string
  ctaHref: string
}

export function Callout({
  text,
  ctaText,
  ctaHref,
  className,
  ...restProps
}: Props): ReactElement {
  return (
    <WidthWrapper
      as="section"
      className={cx('my-16 flex justify-center', className)}
      {...restProps}
    >
      <div className="px-8 py-6 md:flex items-center rounded shadow-lg border border-gray-300">
        <div
          className="text-2xl leading-tight font-bold -mt-1 text-center md:text-left"
          css={css`
            strong {
              font-weight: inherit;
              color: var(--color-accent);
            }
          `}
        >
          {text}
        </div>

        <div className="flex justify-center">
          <Link
            to={ctaHref}
            className={cx(
              'mt-6 md:mt-0 md:ml-8 px-4 md:px-6 pt-2 md:pt-3 pb-3 md:pb-4 rounded shadow-lg bg-accent hover:bg-brand',
              'text-white text-lg md:text-xl text-center font-bold antialiased leading-tight',
            )}
          >
            {ctaText}
          </Link>
        </div>
      </div>
    </WidthWrapper>
  )
}
