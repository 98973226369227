import { graphql } from 'gatsby'
import React, { ReactElement } from 'react'
import { CalloutBlockFragment } from '../../types/graphql'
import { resolvePathByDatoModel } from '../../utils/dato'
import { Callout } from '../Callout'

type Props = {
  data: CalloutBlockFragment
}

export function CalloutBlock({ data }: Props): ReactElement {
  const { textNode, ctaText, ctaLink } = data

  return textNode &&
    textNode.childMarkdownRemark &&
    textNode.childMarkdownRemark.html &&
    ctaText &&
    ctaLink ? (
    <Callout
      text={
        <div
          dangerouslySetInnerHTML={{
            __html: textNode.childMarkdownRemark.html,
          }}
        />
      }
      ctaText={ctaText}
      ctaHref={resolvePathByDatoModel(ctaLink)}
    />
  ) : (
    <div>Callout block is misconfigured.</div>
  )
}

export const fragment = graphql`
  fragment CalloutBlockFragment on DatoCmsCallout {
    textNode {
      childMarkdownRemark {
        html
      }
    }

    ctaText

    ctaLink {
      __typename

      ... on DatoCmsPage {
        slug
      }

      ... on DatoCmsBrand {
        slug
      }

      ... on DatoCmsBrandSubcategory {
        slug
        brand {
          slug
        }
      }

      ... on DatoCmsProduct {
        slug
        brand {
          slug
        }
      }
    }
  }
`
