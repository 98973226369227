import React from 'react'
import { WidthWrapper } from './WidthWrapper'

type Props = {
  heading: string
  logoList: Array<React.ReactElement | null>
}

export function LogoGrid({ heading, logoList }: Props): React.ReactElement {
  return (
    <WidthWrapper as="section" className="my-16">
      <div className="pb-16 border-b border-gray-300">
        <h2 className="text-2xl leading-tight text-center mb-8">{heading}</h2>

        <div className="mt-8 md:mt-0 flex flex-wrap justify-center">
          {logoList.map(logo => (
            <div className="p-8">{logo}</div>
          ))}
        </div>
      </div>
    </WidthWrapper>
  )
}
