import { graphql } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import React, { ReactElement } from 'react'
import { BannerWithCaptionBlock } from '../components/modular-blocks/BannerWithCaption.block'
import { CalloutBlock } from '../components/modular-blocks/Callout.block'
import { LogoGridBlock } from '../components/modular-blocks/LogoGrid.block'
import { WideBannerWithCaptionBlock } from '../components/modular-blocks/WideBannerWithCaption.block'
import { HomePageQuery } from '../types/graphql'

type Props = {
  data: HomePageQuery
}

export default function HomePage({ data }: Props): ReactElement {
  const { seoMetaTags, content } = data.datoCmsHomePage!

  return (
    <>
      <HelmetDatoCms seo={seoMetaTags} />

      <main>
        {content && content.length > 0
          ? content.map(block => {
              if (!block) return null

              switch (block.__typename) {
                case 'DatoCmsWideBannerWithCaption':
                  return <WideBannerWithCaptionBlock data={block} />
                case 'DatoCmsBannerWithCaption':
                  return <BannerWithCaptionBlock data={block} />
                case 'DatoCmsLogoGrid':
                  return <LogoGridBlock data={block} />
                case 'DatoCmsCallout':
                  return <CalloutBlock data={block} />
                default:
                  const _exhaustiveCheck: never = block
                  return _exhaustiveCheck
              }
            })
          : null}
      </main>
    </>
  )
}

export const query = graphql`
  query HomePageQuery {
    datoCmsHomePage {
      seoMetaTags {
        tags
      }

      content {
        __typename

        ... on DatoCmsWideBannerWithCaption {
          ...WideBannerWithCaptionFragment
        }

        ... on DatoCmsBannerWithCaption {
          ...BannerWithCaptionFragment
        }

        ... on DatoCmsLogoGrid {
          ...LogoGridFragment
        }

        ... on DatoCmsCallout {
          ...CalloutBlockFragment
        }
      }
    }
  }
`
