import { Link } from 'gatsby'
import React from 'react'
import { Markdown } from './Markdown'
import { WidthWrapper } from './WidthWrapper'

type Props = {
  image: React.ReactElement
  imageCaption?: string
  heading: string
  subheading?: string
  bodyTextHtml: string
  ctaText?: string
  ctaLink?: string
}

export function BannerWithCaption({
  image,
  imageCaption,
  heading,
  subheading,
  bodyTextHtml,
  ctaText,
  ctaLink,
}: Props): React.ReactElement {
  return (
    <WidthWrapper as="section" className="my-8 lg:my-16">
      <div className="pb-16 border-b border-gray-300 flex flex-col-reverse md:flex-row md:items-center">
        <div className="hidden md:flex flex-col justify-center mt-8 md:w-1/2 md:pr-8">
          {image}

          {imageCaption ? (
            <div className="mt-8 flex justify-center mx-auto px-2 py-1 border border-gray-600 rounded text-sm">
              {imageCaption}
            </div>
          ) : null}
        </div>

        <div className="mt-8 md:w-1/2 md:px-8 max-w-md">
          <h2 className="text-3xl font-bold leading-tight">{heading}</h2>

          {subheading && (
            <p className="mt-4 text-lg text-accent italic">{subheading}</p>
          )}

          {/* repeat of the above */}
          <div className="flex flex-col justify-center mt-8 md:w-1/2 md:pr-8 md:hidden">
            {image}

            {imageCaption ? (
              <div className="mt-8 flex justify-center mx-auto px-2 py-1 border border-gray-600 rounded text-sm">
                {imageCaption}
              </div>
            ) : null}
          </div>

          <Markdown innerHTML={bodyTextHtml} className="mt-8 text-lg" />

          {ctaLink && ctaText ? (
            <Link
              to={ctaLink}
              className="inline-block mt-16 rounded shadow bg-accent text-white font-bold antialiased px-4 pt-2 pb-3 hover:bg-brand"
            >
              {ctaText}
            </Link>
          ) : null}
        </div>
      </div>
    </WidthWrapper>
  )
}
