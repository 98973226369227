import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'
import { WideBannerWithCaptionFragment } from '../../types/graphql'
import { resolvePathByDatoModel } from '../../utils/dato'
import { WideBannerWithCaption } from '../WideBannerWithCaption'

type Props = {
  data: WideBannerWithCaptionFragment
}

export function WideBannerWithCaptionBlock({
  data,
}: Props): React.ReactElement {
  const {
    image,
    overlayTextHeading,
    overlayTextBodyNode,
    ctaText,
    ctaLink,
  } = data

  const overlayTextBodyHtml =
    overlayTextBodyNode &&
    overlayTextBodyNode.childMarkdownRemark &&
    overlayTextBodyNode.childMarkdownRemark.html

  return image && image.fluid ? (
    <WideBannerWithCaption
      image={<Img fluid={image.fluid as FluidObject} alt="" />}
      overlayTextHeading={overlayTextHeading || undefined}
      overlayTextBodyHtml={overlayTextBodyHtml || undefined}
      ctaText={ctaText || undefined}
      ctaLink={ctaLink ? resolvePathByDatoModel(ctaLink) : undefined}
    />
  ) : (
    <div>Wide Banner With Caption block is misconfigured.</div>
  )
}

export const fragment = graphql`
  fragment WideBannerWithCaptionFragment on DatoCmsWideBannerWithCaption {
    image {
      fluid(maxWidth: 1296) {
        ...DatoFluid
      }
    }

    overlayTextHeading
    overlayTextBodyNode {
      childMarkdownRemark {
        html
      }
    }

    ctaText
    ctaLink {
      __typename

      ... on DatoCmsPage {
        slug
      }

      ... on DatoCmsBrand {
        slug
      }

      ... on DatoCmsBrandSubcategory {
        slug
        brand {
          slug
        }
      }

      ... on DatoCmsProduct {
        slug
        brand {
          slug
        }
      }
    }
  }
`
