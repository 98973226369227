import { graphql } from 'gatsby'
import Img, { FluidObject } from 'gatsby-image'
import React from 'react'
import { BannerWithCaptionFragment } from '../../types/graphql'
import { resolvePathByDatoModel } from '../../utils/dato'
import { BannerWithCaption } from '../BannerWithCaption'

type Props = {
  data: BannerWithCaptionFragment
}

export function BannerWithCaptionBlock({ data }: Props): React.ReactElement {
  const {
    image,
    imageCaption,
    heading,
    subheading,
    bodyNode,
    ctaText,
    ctaLink,
  } = data

  return image &&
    image.fluid &&
    imageCaption &&
    heading &&
    bodyNode &&
    bodyNode.childMarkdownRemark &&
    bodyNode.childMarkdownRemark.html &&
    ctaText &&
    ctaLink ? (
    <BannerWithCaption
      image={<Img fluid={image.fluid as FluidObject} alt="" />}
      imageCaption={imageCaption}
      heading={heading}
      subheading={subheading || undefined}
      bodyTextHtml={bodyNode.childMarkdownRemark.html}
      ctaText={ctaText}
      ctaLink={resolvePathByDatoModel(ctaLink)}
    />
  ) : (
    <div>Banner With Caption block is misconfigured.</div>
  )
}

export const fragment = graphql`
  fragment BannerWithCaptionFragment on DatoCmsBannerWithCaption {
    image {
      fluid(
        maxWidth: 648
        maxHeight: 648
        imgixParams: { bg: "0FFF", w: "648", h: "648", fit: "fillmax" }
      ) {
        ...DatoFluid
      }
    }
    imageCaption

    heading
    subheading
    bodyNode {
      childMarkdownRemark {
        html
      }
    }

    ctaText
    ctaLink {
      __typename

      ... on DatoCmsPage {
        slug
      }

      ... on DatoCmsBrand {
        slug
      }

      ... on DatoCmsBrandSubcategory {
        slug
        brand {
          slug
        }
      }

      ... on DatoCmsProduct {
        slug
        brand {
          slug
        }
      }
    }
  }
`
