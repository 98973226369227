import { Link } from 'gatsby'
import React from 'react'
import { ImageOverlay } from './ImageOverlay'
import { WidthWrapper } from './WidthWrapper'

type Props = {
  image: React.ReactElement
  overlayTextHeading?: string
  overlayTextBodyHtml?: string
  ctaText?: string
  ctaLink?: string
}

export function WideBannerWithCaption({
  image,
  overlayTextHeading,
  overlayTextBodyHtml,
  ctaText,
  ctaLink,
}: Props): React.ReactElement {
  const shouldShowButton = !!ctaText || !!ctaLink
  const shouldShowOverlay =
    !!overlayTextHeading || !!overlayTextBodyHtml || shouldShowButton

  return (
    <WidthWrapper as="section" className="my-8 lg:my-16">
      <div className="relative z-1 rounded overflow-hidden shadow">
        {image}

        {shouldShowOverlay && (
          <ImageOverlay className="flex justify-center items-center p-4">
            <div className="text-center">
              {overlayTextHeading && (
                <h2 className="text-white text-2xl sm:text-3xl lg:text-4xl font-bold leading-tight">
                  {overlayTextHeading}
                </h2>
              )}

              {overlayTextBodyHtml && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: overlayTextBodyHtml,
                  }}
                  className="mt-2 lg:mt-4 max-w-md mx-auto text-gray-300 sm:text-lg lg:text-xl"
                />
              )}

              {shouldShowButton && (
                <Link
                  to={ctaLink!}
                  className="inline-block mt-4 lg:mt-8 bg-white px-4 pt-2 pb-3 rounded hover:opacity-75"
                >
                  {ctaText}
                </Link>
              )}
            </div>
          </ImageOverlay>
        )}
      </div>
    </WidthWrapper>
  )
}
