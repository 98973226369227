import React from 'react'
import { cx, hexToRGB } from '../utils/helpers'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
}

const overlayColor = '#2d3748'

export function ImageOverlay({
  className,
  children,
  ...restProps
}: Props): React.ReactElement {
  return (
    <div
      style={{
        backgroundColor: hexToRGB(overlayColor, 0.48),
      }}
      className={cx('absolute inset-0', className)}
      {...restProps}
    >
      {children}
    </div>
  )
}
